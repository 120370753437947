// TranslationContext.js

import React, { createContext, useContext, useState } from 'react';

// Create a new context for language
const TranslationContext = createContext();

// Create a custom hook to use the language context
export function useLanguage() {
  return useContext(TranslationContext);
}

// Create a provider component to wrap the application and provide the language context
export function TranslationProvider({ children }) {
  const [language, setLanguage] = useState("de");

  const handleLanguageChange = (selectedLanguage) => {
    setLanguage(selectedLanguage);
  };

  // Value to be provided by the context
  const value = {
    language,
    setLanguage,
    handleLanguageChange,
  };

  return (
    <TranslationContext.Provider value={value}>
      {children}
    </TranslationContext.Provider>
  );
}
